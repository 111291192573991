import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { get } from "lodash"
import Layout from "../components/layout"
import Heading from "../components/Heading"
import BlogPostList from "../components/Blog/BlogPostList"

const BlogPage = ({ data }) => {
    const { heading } = get(data, "allBlogPostsJson.edges[0].node", {})
    const { copy: headingCopy } = heading
    const { blogPosts: posts = [] } = get(
        data,
        "allBlogPostsJson.edges[0].node",
        {}
    )

    return (
        <Layout>
            <SEO
                title="Blog | Johnnie Aiello"
                description="Quick reads for designers, developers, product managers, or anyone working on a team."
            />
            <Heading headingText={headingCopy.header} />
            <BlogPostList blogPosts={posts} />
        </Layout>
    )
}

export default BlogPage

export const query = graphql`
    query {
        allBlogPostsJson {
            edges {
                node {
                    heading {
                        copy {
                            header
                        }
                    }
                    blogPosts {
                        id
                        title
                        slug
                        article
                        callout
                        videoSrcURL
                        videoTitle
                        image {
                            src {
                                childImageSharp {
                                    fluid(maxWidth: 900, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            alt
                        }
                    }
                }
            }
        }
    }
`
