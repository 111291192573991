import styled from "@emotion/styled"
import { device } from "../styles/device"
import { colors } from "../styles/colors"
import { fontFamily } from "../styles/fonts/fontFamily"

const H2 = styled.h2`
    font-size: 16px;
    line-height: 1.4em;
    margin: 1em 0;
    color: ${colors.grey};
    font-family: ${fontFamily.manrope};

    @media ${device.tablet} {
        font-size: 20px;
    }
`
export default H2
