import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import AppWrapper from "../../styles/AppWrapper"
import ReactHtmlParser from "react-html-parser"
import { colors } from "../../styles/colors"
import { device } from "../../styles/device"
import H2 from "../H2"
import { fontFamily } from "../../styles/fonts/fontFamily"
import Img from "gatsby-image"

const BlogPostList = ({ blogPosts }) => {
    console.log("blogPosts", blogPosts)
    return (
        <>
            <BlogPostsListContainer>
                <AppWrapper>
                    <AllBlogPosts>
                        {blogPosts.map(({ id, title, slug, image }) => (
                            <PostTitleWrapper key={id}>
                                <Link to={`/blog/${slug}`}>
                                    <Img
                                        fluid={image.src.childImageSharp.fluid}
                                        alt={image.alt}
                                    />
                                </Link>
                                <PostTitle>
                                    <BlogPostLink to={`/blog/${slug}`}>
                                        {ReactHtmlParser(title)}
                                    </BlogPostLink>
                                </PostTitle>
                                <ReadIdeaCTA to={`/blog/${slug}`}>
                                    Read
                                </ReadIdeaCTA>
                            </PostTitleWrapper>
                        ))}
                    </AllBlogPosts>
                </AppWrapper>
            </BlogPostsListContainer>
        </>
    )
}

export default BlogPostList

const PostTitleWrapper = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.lightGrey};

  &:first-of-type, :nth-of-type(2) {
        padding-top: 0;

        /* h2 {
            font-size: 40px;
            line-height: 1.4em;
            font-weight: 600;
            letter-spacing: -.09rem;
            margin-top: 0;

            @media ${device.tablet} {
                font-size: 80px;
                line-height: 1.1em;
            }
        } */
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 30px;
  }
`

const PostTitle = styled(H2)`
    font-size: 20px;
    line-height: 1.4em;
    font-family: ${fontFamily.manrope};
    font-weight: 500;
    text-decoration: none;

    @media ${device.tablet} {
        font-size: 26px;
        margin-bottom: 30px;
    }
`

const BlogPostsListContainer = styled.section`
    margin: 100px 0;

    @media ${device.tablet} {
        margin: 150px 0 48px;
    }
`

const AllBlogPosts = styled.div`
    position: relative;
    display: grid;
    grid-gap: 6rem;

    @media ${device.tablet} {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
    }
`

const BlogPostLink = styled(Link)`
    text-decoration: none;
    color: ${colors.black};
`

const ReadIdeaCTA = styled(Link)`
    font-size: 16px;
    text-decoration: none;
    color: ${colors.purple};
    font-weight: 600;
    margin-top: 0;
    display: inline-block;
    font-family: ${fontFamily.manrope};
`
